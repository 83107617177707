import { IDataService } from "../../Services/DataAccess/Abstraction/IDataService";
import { injectable } from "inversify";
import { StringResource } from '../../Domain/Common/StringResource';

export interface IUserMenuService {
    Load(): any;
} 

@injectable()
export class UserMenuService implements IUserMenuService {

    private _dataService: IDataService;

    constructor(dataService: IDataService) {
        this._dataService = dataService;
        this._dataService.set(StringResource.BASE_URL, 10);
    }

    Load(): any {
        return this._dataService.getPaged(1, 'UserMenu');
    }
}