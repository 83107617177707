import { IDataService } from "./Abstraction/IDataService";
import { injectable } from "inversify";

@injectable()
export class ReactDataService implements IDataService {

    private _baseUri: string;
    private _pageSize: number;

    // constructor(baseUri: string, pageSize: number)
    // {
    //     this._baseUri = baseUri;
    //     this._pageSize = pageSize;
    // }
    
    set(baseUri: string, pageSize: number) {
        this._baseUri = baseUri;
        this._pageSize = pageSize;
    }

    getPaged(page: number, uriPart?: string | undefined): any {

        //var uri = this._baseUri + uriPart + page.toString() + '/' + this._pageSize.toString();
        fetch(this._baseUri)
            .then(response => {
                return response.json();
            });
    }
    get(uriPart?: string | undefined, data?: any): any {

        //var uri = this._baseUri + uriPart + page.toString() + '/' + this._pageSize.toString();
        fetch(this._baseUri)
            .then(response => {
                return response.json();
            });
    }

    getBlob(uriPart?: string | undefined, data?: any): any {
        throw new Error("Method not implemented.");
    }

    post(data: any, uriPart?: string | undefined, mapResult?: boolean | undefined): any {
        throw new Error("Method not implemented.");
    }

    put(data: any, uriPart?: string | undefined): any {
        throw new Error("Method not implemented.");
    }

    putJson(data: any, uriPart?: string | undefined): any {
        throw new Error("Method not implemented.");
    }

    postJson(data: any, uriPart?: string | undefined, mapResult?: boolean | undefined): any {
        throw new Error("Method not implemented.");
    }

    delete(id: number, uriPart?: string | undefined): any {
        throw new Error("Method not implemented.");

    }

    deleteExtended(data: any, uriPart?: any): any {
        throw new Error("Method not implemented.");
    }


}