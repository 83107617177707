import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import UserMenu from './UserMenu'

import { Outlet } from "react-router";
import { msalInstance } from "../../Auth/AuthConfig";
import Loader from './Loader';
import logo from '../../logo1.svg'
import chevronUp from '../../svg/chevron-up.svg';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })
(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function RootLayout() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

      const handleRedirect = () => {
        const allAccounts = msalInstance.getAllAccounts();
        if (allAccounts.length > 0) {
          var myAccount = allAccounts[0];
          msalInstance
            .logout({
              account: myAccount,
              postLogoutRedirectUri: "/",
            })
            .then(() => {
              console.log("Logout successful.");
            })
            .catch((error) => {
              console.error("Logout error:", error);
            });
        } else {
          msalInstance.logout();
        }
      };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
    <Loader />
    <Box sx={{ flexGrow: 1}}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar color='grey'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img className='cursor-pointer' src={logo} />
          <Typography variant="subtitle1" noWrap component="div" sx={{ flexGrow: 1 }}>
            API Analytics Dashboard
          </Typography>
          <Button onClick={handleRedirect} color="inherit">Sign Out</Button>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open} sx={{ display: 'flex' }}>
        <DrawerHeader> 
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <UserMenu />         
      </Drawer>
      <Box position={'fixed'} component="main" sx={
                                              { flexGrow: 1, 
                                              paddingLeft :"85px", 
                                              paddingTop : "65px", 
                                              paddingRight:"10px", 
                                              paddingBottom :"10px",
                                              width : "100%" }}>
        <Outlet /> 
      </Box>
    </Box>
    </>
  );
}