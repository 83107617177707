interface AppConfig {
    IDENTITY_AUTHORITY: string;
    IDENTITY_CLIENT_ID: string;
    SCOPE: string;
    API_URL: string;
    EXTENSION_MICRO_URL: string;
    USE_MICROFRONTEND: boolean | string;
  }
  
  const config: AppConfig = {
    IDENTITY_AUTHORITY: process.env.REACT_APP_IDENTITY_AUTHORITY || "",
    IDENTITY_CLIENT_ID: process.env.REACT_APP_IDENTITY_CLIENT_ID || "",
    SCOPE: process.env.REACT_APP_SCOPE || "",
    API_URL: process.env.REACT_APP_API_URL || "",
    EXTENSION_MICRO_URL : process.env.REACT_APP_EXTENSION_MICRO_URL || "",
    USE_MICROFRONTEND : process.env.REACT_APP_USE_MICROFRONTEND || false
  };
  
  Object.freeze(config);
  
  export default config;  