import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { injectable } from 'inversify';

export interface INotification {
    success(message: string): void;
    warn(message: string): void;
    error(message: string): void;
    info(message: string): void;
}

@injectable()
export class Notification implements INotification {

    success(message: string): void {
        toast.success(<div>
            <span style={{ marginLeft: 10 }}>{message}</span></div>, {
            position:"bottom-right"
        });
    }
    warn(message: string): void {
        toast.warn(<div> 
            <span style={{ marginLeft: 10 }}>{message}</span></div>, {
            position: "bottom-right"
        });
    }
    error(message: string): void {
        toast.error(<div> 
            <span style={{ marginLeft: 10 }}>{message}</span></div>, {
            position: "bottom-right"
        });
    }
    info(message: string): void {
        toast.info(<div> 
            <span style={{ marginLeft: 10 }}>{message}</span></div>, {
            position: "bottom-right"
        });
    }
}


export class Toaster extends React.Component<any, any> {
    
    render() {
        return (
            <div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                    pauseOnHover
                />
                
            </div>
        );
    }
}