
import { useEffect, useRef, useState } from "react";
import GtrTable from "../table/Table";
import { PAGESIZE } from "../../helper/Constants";
import { defaultCellFormatter, defaultFormatter, indexFormatter, taxPayerNameFormatter } from "../../helper/Formatter";
import { TableHeaderColumn, TextFilter } from "react-bootstrap-table";
import { TYPES } from "../../Startup/types";
import { ICompanyPolicyService } from "../../Core/Services/Policy/CompanyPolicyService";
import container from '../../Startup/inversify.config';
import { IProductSubscriptionService } from "../../Core/Services/Subscription/ProductSubscription";
import RateLimitTable from "./RateLimitTable";
// import '../../styles/_tables.scss';
import RefreshIcon from '@mui/icons-material/Refresh';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Button, Col } from 'react-bootstrap';

const productSubscriptionService = container.get<IProductSubscriptionService>(TYPES.IProductSubscriptionService);

interface GridData
{
  companyProductSubscriptions:[],
  totalCount:number
}
export default function RateLimitAccess()
{ 
  const [companySubscription, setcompanySubscription] = useState<GridData>();
  const [count,setCount]= useState<number>(0);



  const getAllCompanySubscription = (pageNo:number, PageSize:number) :void =>
        {
            productSubscriptionService.Load(pageNo,PageSize).then(function (response: any) {
              console.log(response.data.data);
              setcompanySubscription(response.data.data);
              setCount(response.data.data.totalCount);
            })
            .catch(function (error: any) {
                    console.log(error);
            });
          }

    const getDefaultPageCompanySubscription = () =>
    {
      getAllCompanySubscription(1,10);
    };

    useEffect(()=> 
    {
      getDefaultPageCompanySubscription();
    },[]);

    return <div style={{width : "98%"}}>
   
    <Form style={{paddingBottom : '25px'}}>
        <Row className="mb-12">
        <Form.Group as={Col} style={{display : "flex", justifyContent : 'flex-start', paddingTop : '15px'}}>
        <h5>Rate Limit Access</h5>
        </Form.Group>
        <Form.Group as={Col} style={{display : "flex", justifyContent : 'flex-end'}}>
            <Button title='Refresh' variant="outlined" onClick={()=> getDefaultPageCompanySubscription() } ><RefreshIcon /></Button>
        </Form.Group>
        </Row>
    </Form>
    <RateLimitTable data={companySubscription?.companyProductSubscriptions} count={count} refresh={getAllCompanySubscription}/> 
</div> 
}