import { injectable } from "inversify";

export interface IWindowUtilities {
    FullScreen(elementId: string): void;
    hideTopBar(): void;
    showTopBar(): void;
    showLoader(hideForcefully?: boolean): void;
    hideLoader(hideForcefully?: boolean): void;
    startTutorial(jsonData: string): void;
    drawLine(fromElementId: string, toElementId: string): string;
    removeLine(lineId: string): void;
    removeAllLines(): void;
    redrawLine(lineId: string): void;
    redrawAllLines(): void;
    // highlightLine(lineId: string): void;
    // removeHighlightLine(lineId: string): void;
}

declare var introJs: any;
declare var jqSimpleConnect: any;

@injectable()
export class WindowUtilities implements IWindowUtilities {

    FullScreen(elementId: string): void {

        let ele: any = document.getElementById(elementId);

        if (ele.requestFullscreen) {
            ele.requestFullscreen();
        } else if (ele.msRequestFullscreen) {
            ele.msRequestFullscreen();
        } else if (ele.mozRequestFullScreen) {
            ele.mozRequestFullScreen();
        } else if (ele.webkitRequestFullscreen) {
            ele.webkitRequestFullscreen();
        } else {
            alert("Full screen not available");
        }
    }

    hideTopBar(): void {

        let childElement: any = document.getElementById("childContainer");
        let topBarElement: any = document.getElementById("topbar");

        childElement.style.marginTop = "-50px";
        topBarElement.style.display = "none";
    }

    showTopBar(): void {

        let childElement: any = document.getElementById("childContainer");
        let topBarElement: any = document.getElementById("topbar");

        childElement.style.marginTop = "";
        topBarElement.style.display = "";
    }

    showLoader(hideForcefully: boolean = false): void {
        if (hideForcefully) {
            const loaderElement = document.getElementById("loader");
            if (loaderElement) {
                loaderElement.setAttribute("data-manual-hide", "true");
            }
        }

        document.getElementById("loader")?.classList.remove('invisible');
        document.getElementById("loader")?.classList.add('visible');
        document.body.classList.add('loading-shading-mui');
    }

    hideLoader(hideForcefully: boolean = false): void {
        const loaderElement = document.getElementById("loader");
        if (loaderElement !== null && (hideForcefully || loaderElement.getAttribute("data-manual-hide") !== "true")) {
            loaderElement.removeAttribute("data-manual-hide");
            loaderElement.classList.remove('visible');
            loaderElement.classList.add('invisible');
            document.body.classList.remove('loading-shading-mui');
        }
    }

    public startTutorial(jsonData: string): void {

        introJs().setOptions({
            exitOnEsc: false,
            exitOnOverlayClick: false,
            steps: jsonData
        }).onchange(function () {


        }).onexit(function () {


        }).oncomplete(function () {


        }).start();
    }

    public drawLine(fromElementId: string, toElementId: string): string {

        return jqSimpleConnect.connect("#" + fromElementId,
            "#" + toElementId, { radius: 1, color: 'red', anchorA: 'vertical', anchorB: 'vertical', roundedCorners: true });
    }
    
    public removeAllLines(): void {
        jqSimpleConnect.removeAll();
    }

    public removeLine(lineId: string): void {
        jqSimpleConnect.removeConnection(lineId);
    }

    public redrawLine(lineId: string): void {
        jqSimpleConnect.repaint(lineId);
    }

    public redrawAllLines(): void {
        setTimeout(function () {
            jqSimpleConnect.repaintAll();
        }, 100);
    }

    // public highlightLine(lineId: string): void {
    //     $("#" + lineId + "_1").addClass("jqSimpleConnectHighlight");
    //     $("#" + lineId + "_2").addClass("jqSimpleConnectHighlight");
    //     $("#" + lineId + "_3").addClass("jqSimpleConnectHighlight");
    // }

    // public removeHighlightLine(lineId: string): void {
    //     $("#" + lineId + "_1").removeClass("jqSimpleConnectHighlight");
    //     $("#" + lineId + "_2").removeClass("jqSimpleConnectHighlight");
    //     $("#" + lineId + "_3").removeClass("jqSimpleConnectHighlight");
    // }
}