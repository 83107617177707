import { IDataService } from "../DataAccess/Abstraction/IDataService";
import { injectable } from "inversify";
import { StringResource } from '../../Domain/Common/StringResource';
import { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import moment from 'moment';


export interface IEndpointUsageByEndpointService {
    Load(pageNo :number, pageSize :number,fromDate:Date, toDate:Date, productType : ProductType, companyId : number): any;
}

@injectable()
export class EndpointUsageByEndpointService implements IEndpointUsageByEndpointService {

    private _dataService: IDataService;

    constructor(dataService: IDataService) {
        this._dataService = dataService;
        this._dataService.set(StringResource.BASE_URL, 100);
    }

    Load(pageNo :number, pageSize :number,fromDate:Date, toDate:Date, productType : ProductType, companyId : number): any {
        const sDate = moment(fromDate).format('MM/DD/YYYY');
        const eDate = moment(toDate).format('MM/DD/YYYY');
        return this._dataService.getPaged(1, 
            `ApiUsageByEndpoint/GetUsage?pageNo=${pageNo}
            &pageSize=${pageSize}
            &fromDate=${sDate}
            &toDate=${eDate}
            &productType=${productType??0}
            &companyId=${companyId??0}`);
    }
}