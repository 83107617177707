import { Container } from "inversify";
import "reflect-metadata";
import { IUserMenuService } from "../Core/Services/UserMenu/UserMenuService";
import { IUserService } from "../Core/Services/User/UserService";
import { TYPES } from "./types";
import { ServiceFactory } from "../Factory/ServiceFactory";
import { ICompanyPolicyService } from "../Core/Services/Policy/CompanyPolicyService";
import { IProductSubscriptionService } from "../Core/Services/Subscription/ProductSubscription";
import { IEndpointUsageByEndpointService } from "../Core/Services/EndPointUsage/EndpointUsageByEndpointService";

const container = new Container();

container.bind<IUserMenuService>(TYPES.IUserMenuService).toConstantValue(new ServiceFactory().CreateUserMenuService());
container.bind<IUserService>(TYPES.IUserService).toConstantValue(new ServiceFactory().CreateUserService());
container.bind<ICompanyPolicyService>(TYPES.ICompanyPolicyService).toConstantValue(new ServiceFactory().CreateCompanyPolicyService());
container.bind<IProductSubscriptionService>(TYPES.IProductSubscriptionService).toConstantValue(new ServiceFactory().CreateProductSubscriptionService());
container.bind<IEndpointUsageByEndpointService>(TYPES.IEndpointUsageByEndpointService).toConstantValue(new ServiceFactory().CreateEndpointUsageByEndpointService());

export default container;