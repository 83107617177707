import { createBrowserRouter, Route  } from 'react-router-dom';
import RootLayout from './Components/Layout/RootLayout';
import Dashboard from './Components/Admin/Dashboard';
import ApiUsageByEndpoint from './Components/Analytics/ApiUsage/ByEndpoint/ApiUsageByEndpoint';
import Webhook from './Components/Analytics/ApiUsage/Webhook';
import RateLimitAccess from './Components/RateLimit/RateLimitAccess';
import ApiUsageByCompany from './Components/Analytics/ApiUsage/ByCompany/ApiUsageByCompany';



interface RouteProps {
    component: any
}
const Wrapper: React.FC<RouteProps> = ({ component }): React.ReactElement => {
    return <div id="layout-wrapper">{component}</div> ;
};

export const routes = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        children:[
            { index:true, element: <Wrapper component={<RateLimitAccess />} /> },
            { path: 'rate-limit', element: <Wrapper component={<RateLimitAccess />} />},
            { path: 'usageby-endpoint', element: <Wrapper component={<ApiUsageByEndpoint />} /> },
            { path: 'usageby-company', element: <Wrapper component={<ApiUsageByCompany />} /> },
            { path: 'webhook', element: <Wrapper component={<Webhook />} /> }
        ]
    }
]);
