import * as React from "react";

const AuthLoader = (props: any) => {
  const { navigate } = props;

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      navigate();
    });
    return () => clearTimeout(timeout);
  }, [navigate]);

  return <span className="login-loader" />;
};

export default AuthLoader;
