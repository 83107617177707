import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import StarBorder from '@mui/icons-material/StarBorder';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigationMenuDescription } from '../../Core/Domain/ViewModel/NavigationMenuDescription';
import { IUserMenuService } from '../../Core/Services/UserMenu/UserMenuService';
import container from '../../Startup/inversify.config';
import { TYPES } from '../../Startup/types';
import { stringLiteral } from '@babel/types';
import MenuIcon from '@mui/icons-material/Menu';
import { IUserService } from '../../Core/Services/User/UserService';
import { SideMenu } from '@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { SideMenuIconSelector, SideMenus } from '../../Assets/icons/IconCollection';

const userMenuService = container.get<IUserMenuService>(TYPES.IUserMenuService);
const userService = container.get<IUserService>(TYPES.IUserService);

export default function NestedList() {
    const [open, setOpen] = useState('');
    const [userName, setUserName] = useState('');
    const [userMenu, setUserMenu] = useState(new NavigationMenuDescription());
    const [userDataLoaded, setUserDataLoaded] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {

        userService.GetCurrentUser().then(function (response: any) {
            console.log(response.data); 
            setUserName(response.data);
        })
        .catch(function (error: any) {
                console.log(error);
        });

        userMenuService.Load().then(function (response: any) {
            setUserMenu(response.data);
            setUserDataLoaded(!userDataLoaded);
        })
        .catch(function (error: any) {
            console.log(error);
        });
    }, []);

    const handleClick = (menu: any) => {
        setOpen(menu);
    };

    const getMenuIconEnum = (stringName : string) : SideMenus =>
    {
        console.log(stringName);
        switch(stringName)
        {
            case "build":
            case "usage":
                return SideMenus.RateLimit;
            case "event_note":
                return SideMenus.EndPointUsage;
            case "WebHookUsage":
                return SideMenus.WebHookUsage;
            default :
            return SideMenus.None; 
        }
    }


    const getSubMenus = (menu: NavigationMenuDescription, index: number) => {
        return (
            menu.isParent == false ?
            <>{getListSubmenu(menu,index,true)} </>
            :<>
                <ListItemButton key={menu.name + index} onClick={() => handleClick(menu.name)}>
                    <ListItemIcon style={{minWidth : "47px"}}><SideMenuIconSelector menuName={getMenuIconEnum(menu.icon)}/> </ListItemIcon>
                    <ListItemText primary={menu.displayName} />
                    {open === menu.name ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>                
                <Collapse in={open === menu.name} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            menu.items.map((subMenu: NavigationMenuDescription, i: number) => 
                                {
                                let returnValue: any;
                                returnValue = (subMenu.items.length) === 0 ?
                                    getListSubmenu(subMenu, i) :
                                    getSubMenus(subMenu, i);
                                return returnValue;
                            })
                        }
                    </List>
                </Collapse>
            </>
        );
    }

    const getListSubmenu = (subMenu: NavigationMenuDescription, index: number, isMainMenu?: boolean) => {
        return (
            <ListItemButton component={Link} to={subMenu.target}
                key={subMenu.name + index}
                sx={{ pl: isMainMenu? 2:4, bgcolor: (pathname === subMenu.target ? 'grey' : '') }} >
                <ListItemIcon >
                    {/*isMainMenu ? <PlayArrowIcon /> : <DoubleArrowIcon />*/}
                    
                </ListItemIcon>
                <ListItemText primary={subMenu.displayName} />
            </ListItemButton>
        );
    } 


    return (
        <>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader" >
                {
                    userDataLoaded &&
                    userMenu.items?.map(
                        (menu: NavigationMenuDescription, index: number) => getSubMenus(menu, index)
                    )
                }
            </List>
        </>
    );
}