import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import CustomDropdown, { SelectOptions }  from '../../../UIComponent/customDropdown';
import { useEffect, useState } from 'react';
import CustomDatepicker from '../../../UIComponent/customDatepicker';
import { TYPES } from '../../../../Startup/types';
import { IEndpointUsageByEndpointService } from '../../../../Core/Services/EndPointUsage/EndpointUsageByEndpointService';
import container from '../../../../Startup/inversify.config';
import Pagination from '../../../UIComponent/Pagination';
import "../../../../styles/pagination.scss";
import { SizePerPageList } from '../../../../helper/HelperFunctions';
import { PAGESIZE } from '../../../../helper/Constants';


const endPointService = container.get<IEndpointUsageByEndpointService>(TYPES.IEndpointUsageByEndpointService);

export const productTypes: SelectOptions[] = [
    { label: 'SafeSend Returns', value: "1" },
	{ label: 'Safesend Signatures', value: "2" },
	{ label: 'SafeSend MailMerge', value: "3" },
	{ label: 'SafeSend Organizers', value: "4" },
	{ label: 'SafeSend Extensions', value: "5" },
    { label: 'SafeSend Exchange', value: "6" },
    { label: 'SafeSend Gather', value: "7" },
    { label: 'SafeSend TicTie Calculate', value: "8" },
    { label: 'SafeSend Portal Client', value: "1001" },
];

export const sampleCompany: SelectOptions[] = [
    { label: 'Company 1', value: "1" },
    { label: 'Company 2', value: "2" },
    { label: 'Company 3', value: "3" },
    { label: 'Company 4', value: "4" },
];

export interface GridData
{
    endpointUsageByEndPoints:[],
    totalResult:number
}

export default function ApiUsageByEndpoint()
{
    const today = new Date();
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [selectedCompany, setSelectedCompany] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(today);
    const [endDate, setEndDate] = useState<any>(today);
    const [hideInternalAccount, setHideInternalAccount] = useState<boolean>(true);   
    const [gridData, setGridData] = useState<GridData>();

    const [pageNo,setPageNo]= useState<number>(1);
    const [pageSize,setPageSize]= useState<number>(SizePerPageList[0]);

    const [count,setCount]= useState<number>(0);

    const onPageChange = (page: number, pageSize: number) => {
        setPageNo(page);
        setPageSize(pageSize);
        getData();
    }
    const getData = () =>
    {
        endPointService.Load(pageNo,pageSize,startDate,endDate,selectedProduct,selectedCompany).
                then(function (response: any) 
                {
                    const data = response.data.data;
                    setGridData(data);                    
                })
                .catch(function (error: any) {
                        console.log(error);
                });
    };

    useEffect(()=> 
        {
            if(gridData)
                { setCount(gridData.totalResult); }
        },[gridData]);

    
    useEffect(()=> 
        {
            getData();
        },[]);

    
    return <div style={{width : "98%"}}>
    <h5>Endpoint Usage Report by Endpoint</h5>
    <>
    <Form>
        <Row className="mb-12">
        <Form.Group as={Col} style={{display : "flex", justifyContent : 'flex-end'}}>
            <Button title='Refresh' variant="outlined" onClick={()=> getData()} ><RefreshIcon /></Button>
        </Form.Group>
        </Row>
    </Form>
    <Form>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>From Date</Form.Label>          
          <CustomDatepicker 
            selectedDate={startDate} 
            setSelectedDate={(date) => setStartDate(date)}
            className='commonDatepicker' />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>To Date</Form.Label>
          <CustomDatepicker 
            selectedDate={endDate} 
            setSelectedDate={(date) => setEndDate(date)} 
            className='commonDatepicker' 
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Product Type</Form.Label>
          <CustomDropdown 
            id='productTypeDropdown' 
            selectedOption={selectedProduct} 
            setSelectedOption={(option) => setSelectedProduct(option)} 
            options={productTypes} 
            customPlaceHolder='Select Product Type' 
            clearable={false} 
            searchable={false} 
            height={38} 
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Company</Form.Label>
          <CustomDropdown 
            id='companyDropdown' 
            selectedOption={selectedCompany} 
            setSelectedOption={(option) => setSelectedCompany(option)} 
            options={sampleCompany} 
            customPlaceHolder='Select Company' 
            clearable={false} 
            searchable={false} 
            height={38} 
            />
        </Form.Group>

        <Form.Group as={Col}>
          <br/>
          
          <Form.Check
            inline
            type={'checkbox'}
            label={'Hide Internal Accounts'}
            id={"chkHideIAccount"}
            checked={hideInternalAccount}
            onChange={()=> setHideInternalAccount(!hideInternalAccount)}
            style={{paddingTop:"13px"}}
          />
        </Form.Group>        
      </Row>
      </Form>

<div style={{ overflowY : "scroll", height:"400px"}}>
    <table className='table usersListTable'>        
        <thead>
            <tr>
                <th
                    className='userGroupsCol'
                    scope='col'>
                    Endpoint&nbsp;Name
                </th>
                <th
                    className='userGroupsCol'
                    scope='col'>
                    Success&nbsp;Count&nbsp;
                </th>
                <th
                    className='userGroupsCol'
                    scope='col'>
                    Total&nbsp;Count
                </th>                           
            </tr>
        </thead>
        <tbody>
        {gridData  == undefined || gridData.endpointUsageByEndPoints?.length === 0 ?  
        (
          <tr>
            <td data-toggle='collapse' colSpan={4} className='usersLoadingArea text-center'>
                No matching records found
            </td>
          </tr>
        ):
        (
            gridData.endpointUsageByEndPoints.map((dat:any) =>
            { return <tr key={dat.companyId} className='userRow'>
                <td  className="nameColData" title={dat.endPointName}>{dat.endPointName} </td>
                <td >{dat.successCount}</td>
                <td >{dat.totalCount}</td>
                </tr> }
        ))}
        </tbody>
    </table>
</div>
    <div>
        <Pagination
            totalCount={count}
            pageIndex={pageNo}
            pageSize={pageSize}
            defaultPageSize={PAGESIZE}
            goToPage={onPageChange}
            sizePerPageList={SizePerPageList}
            showSizePerPageList={true}
            showGoToPages={true}
            showpageCount={false}
            ShowListAndGoto={true}
        />
    </div>
    </>
    </div>
    };