import { injectable } from "inversify";
import { IDataService } from "../Services/DataAccess/Abstraction/IDataService";
import { StringResource } from "../Domain/Common/StringResource";
import { IConfigReader } from "./ConfigReader";

export interface ILocalStorageConfigReader {
    Get(key: string): any;
    Set(uriPart?: string): void;
}

@injectable()
export class LocalStorageReader implements IConfigReader {
    
    private _dataService: IDataService;

    constructor(dataService: IDataService) {
        this._dataService = dataService;
        this._dataService.set(StringResource.CONFIG_SETTINGS_BASE_URL, 10);
    }
    Get(key: string, section?: string): any {
        if (localStorage.getItem(key) == null) {
            this.Set("GetClientConfigSettings")
        }
        return localStorage.getItem(key);
    }

    Set(uriPart?: string): void{
            this._dataService.get(uriPart).then((response: any) => {
                response.data.map((config: any) => {                    
                    localStorage.setItem(config.Key, config.Value);
                })
            }
            );
    }

    Store(key: string, value: string): void {
        try {
            // Exception can be thrown when Browser Quota exceeds for the user. Mostly when data setting in localstorage is huge
            localStorage.setItem(key, value);
        } catch (error) {
            console.error(error)
        }
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

}