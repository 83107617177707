import { injectable } from "inversify";
import { StringResource } from '../../Domain/Common/StringResource';
import { IDataService } from "../../Services/DataAccess/Abstraction/IDataService";
import { IConfigReader } from "../../Utilities/ConfigReader";

export interface IUserService {
    GetCurrentUser(): any;
}

@injectable()
export class UserService implements IUserService {
    private _dataService: IDataService;
    private _configReader: IConfigReader;

    constructor(dataService: IDataService, configReader: IConfigReader) {
        this._dataService = dataService;
        this._configReader = configReader;
        this._dataService.set(StringResource.BASE_URL, 10);
    }

    GetCurrentUser(): any {
        return this._dataService.getPaged(1, 'user/GetUserDetails');
    }
}