import { SortOrder } from "react-bootstrap-table";
import { SortAscending, SortDescending, SortInitial } from "../Assets/icons/IconCollection";

export const sortRenderer = (direction: SortOrder | null, fieldName: string) => {
    if (fieldName === "button") {
      return <></>;
    }
    if (!direction) {
      return <span style={{ cursor: 'pointer' }}><SortInitial /></span>;
    }
  
    return direction === "asc" ? <span style={{ cursor: 'pointer' }}><SortAscending /></span> : <span style={{ cursor: 'pointer' }}><SortDescending /></span>;
  };
  
  export function formattedClientName(clientName: string) {
    if (clientName == undefined) {
      return "";
    } else {
      const nameList = clientName.trim().split(/ +/).reverse();
      if (nameList.length > 2) {
        return nameList[0] + ", " + nameList.slice(1).reverse().join(" ");
      } else {
        return nameList.join(", ");
      }
    }
  }
  export function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  export const SizePerPageList = [10, 20, 30, 40, 50];