import { UserMenuService } from '../Core/Services/UserMenu/UserMenuService';
import { UserService } from '../Core/Services/User/UserService';
import { ReactDataService } from '../Core/Services/DataAccess/DataService.React';
import { AxiosDataService } from '../Core/Services/DataAccess/DataService.Axios';
import { Notification } from '../Components/Common/Toaster';
import { WindowUtilities } from '../Core/Utilities/WindowUtilities';
import { LocalStorageReader } from '../Core/Utilities/LocalStorageReader';
import { CompanyPolicyService } from '../Core/Services/Policy/CompanyPolicyService';
import { ProductSubscriptionService } from '../Core/Services/Subscription/ProductSubscription';
import { EndpointUsageByEndpointService } from '../Core/Services/EndPointUsage/EndpointUsageByEndpointService';

export class ServiceFactory {
    private _env: string;

    constructor() {

        this._env = "SERVER";

    }

    public CreateLocalStorageReader(): any {

        this._env = "SERVER";

        switch (this._env) {

            case "TEST":
                return new LocalStorageReader(this.CreateDataService());

            case "SERVER":
                return new LocalStorageReader(this.CreateDataService());

            default:
                throw new Error("Method not implemented.");
        }

    }

    public CreateDataService(): any {

        switch (this._env) {

            case "TEST":
                return new ReactDataService();

            case "SERVER":
                return new AxiosDataService(new Notification(), new WindowUtilities());

            default:
                throw new Error("Method not implemented.");
        }
    }

    public CreateUserMenuService(): any {

        switch (this._env) {
            case "TEST":
                return new UserMenuService(this.CreateDataService());
            case "SERVER":
                return new UserMenuService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }

    public CreateCompanyPolicyService(): any {

        switch (this._env) {
            case "TEST":
                return new CompanyPolicyService(this.CreateDataService());
            case "SERVER":
                return new CompanyPolicyService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }

    public CreateProductSubscriptionService(): any {

        switch (this._env) {
            case "TEST":
                return new ProductSubscriptionService(this.CreateDataService());
            case "SERVER":
                return new ProductSubscriptionService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }

    public CreateEndpointUsageByEndpointService(): any {

        switch (this._env) {
            case "TEST":
                return new EndpointUsageByEndpointService(this.CreateDataService());
            case "SERVER":
                return new EndpointUsageByEndpointService(this.CreateDataService());
            default:
                throw new Error("Method not implemented.");
        }
    }

    

    public CreateUserService(): any {

        switch (this._env) {
            case "TEST":
                return new UserService(this.CreateDataService(), this.CreateLocalStorageReader());
            case "SERVER":
                return new UserService(this.CreateDataService(), this.CreateLocalStorageReader());
            default:
                throw new Error("Method not implemented.");
        }
    }
}