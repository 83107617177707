import { RouterProvider } from 'react-router-dom';
import { routes } from '../Routes';
import { useEffect, useState } from 'react';
import axios from "axios";
import { loginRequest } from './AuthConfig'

export default function TokenConfig(props: any){
    const { msalInstance } = props;
    const [loading, setLoading] = useState(true);
    const [accessToken, setAccessToken] = useState<string | null>(null);

    useEffect(() => {
        if (accessToken){
          localStorage.setItem('ApiDashboardToken', accessToken);
        }
      }, [accessToken]);
    
      useEffect(() => {
        axios.interceptors.request.use(request => {
          const token = localStorage.getItem('ApiDashboardToken');
          if(token){
            request.headers.Authorization = `Bearer ${token}`;
          }
          return request;
        })

        const getAccountInfo = () => {
          const accounts = msalInstance.getAllAccounts();
          return accounts[0];
        };
    
        const refreshAccessToken = async () => {
          const account = getAccountInfo();
          try {
            const token = await msalInstance.acquireTokenSilent({
              account,
              scopes: loginRequest.scopes,
            });
            setAccessToken(token.accessToken);
            setLoading(false);
          } catch (error) {
            if (error) {
              msalInstance
                .acquireTokenPopup(loginRequest)
                .then((resp: any) => {
                  setAccessToken(resp.accessToken);
                  setLoading(false);
                })
                .catch((err: any) => {
                  console.log(err);
                  setLoading(false);
                });
            } else {
              console.log(error);
              setLoading(false);
            }
          }
        };
        refreshAccessToken();
    }, []);

    if (loading) {
        return <p>loading.....</p>;
    }
      
    return(
      <div className='layout-wrapper'> <RouterProvider router={routes} /> </div>
    );
} 