export class NavigationMenuDescription {

    name: string
    displayName: string
    supportingData: any
    order: number
    icon: string
    target: string
    checkAuthentication: boolean
    isParent: boolean
    isEnabled: boolean
    isVisible: boolean
    items: NavigationMenuDescription[]

    constructor() {
        this.items = [];
    }
}
