import {
    Configuration,
    AccountInfo,
    EventType,
    PublicClientApplication,
  } from "@azure/msal-browser";
import EnvConfig from "./EventConfig";

  export const msalConfig: Configuration = {
    auth: {
      clientId: `${EnvConfig.IDENTITY_CLIENT_ID}`,
      authority: `${EnvConfig.IDENTITY_AUTHORITY}`,
      redirectUri: "/",
      postLogoutRedirectUri: "/",
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
  };
  
  export const loginRequest = {
    scopes: [EnvConfig.SCOPE],
  };
  
  export const msalInstance = new PublicClientApplication(msalConfig);
  
  export const handleAuthenticationEvents = () => {
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      msalInstance.setActiveAccount(msalInstance.getActiveAccount()!);
    }
  
    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const account = event.payload as AccountInfo;
        msalInstance.setActiveAccount(account);
      }
    });
  };
  